import {get, postJ} from "@/request";
import {IPageRes} from "@/apis/page";
import {ISpecs, ISpecsQuery, ISpecsValue, ISpecsValueQuery} from "@/apis/productSpecs/types";



/**
 * 商品规格列表查询
 * @param params 查询参数
 */
export const queryProductSpecsValueListApi = (params: ISpecsValueQuery) => get<ISpecsValue[]>("/golf/productspecsvalue/queryByList", params)

/**
 * 商品规格属性列表
 * @param params 查询参数
 */
export const queryProductSpecsListApi = (params: ISpecsQuery) => get<ISpecs[]>("/golf/productspecs/queryProductspecsList", params);

